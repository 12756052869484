<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Total Partners
          </v-card-title>
          <v-card-text class="text-h6">
            {{ total_partners.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Partnered Users
          </v-card-title>
          <v-card-text class="text-h6">
            {{ partnered_users.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Subscribed Users
          </v-card-title>
          <v-card-text class="text-h6">
            {{ subscribed_users.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Payment Received
          </v-card-title>
          <v-card-text class="text-h6">
            {{ payment_received.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="search"
          dense
          style="height: 38px"
          class="pa-2 white"
          placeholder="Search with name, unique ID, Link"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-btn depressed class="capitalize primary" rounded @click="openDialog">
          <v-icon
            class="pa-1 white primary--text rounded-circle ml-n3"
            size="18"
          >
            mdi-plus
          </v-icon>
          Add Partner
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="partners"
          :search="search"
          :items-per-page="10"
          :loading="loading"
          loading-text="Fetching partners, please wait..."
        >
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn @click="editPartner(item)" icon>
                <v-icon color="primary">mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn @click="deletePartner(item)" icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog max-width="400" v-model="dialog">
      <v-card flat>
        <v-form lazy-validation ref="form" class="text-left">
          <v-toolbar class="primary white--text font-weight-bold" flat>
            <div v-if="item.name">Edit {{ item.name }} category</div>
            <div v-else>Add New category</div>
            <v-spacer></v-spacer>
            <v-icon @click="closeDialog" color="white" size="20">
              mdi-close
            </v-icon>
          </v-toolbar>
          <div class="pa-5">
            <label>Name <span class="error--text">*</span></label>
            <v-text-field
              v-model="form.name"
              outlined
              rounded
              background-color="secondary"
              type="text"
              dense
              placeholder="Enter category name"
              :rules="validate.required('name')"
            ></v-text-field>
            <label>Allocated minutes</label>
            <v-text-field
              v-model="form.minutes"
              rounded
              background-color="secondary"
              dense
              outlined
              placeholder="Enter minutes users can get"
            ></v-text-field>
            <v-btn
              block
              rounded
              depressed
              :loading="loadBtn"
              class="capitalize primary"
              @click="
                $refs.form.validate()
                  ? item.name
                    ? updatePartner(item)
                    : addPartner()
                  : null
              "
            >
              {{ item.name ? "Update" : "Submit" }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      color="error darken-1"
      v-model="snackbar"
      :timeout="3000"
      right
      bottom
    >
      {{ error_text }}
    </v-snackbar>
  </div>
</template>

<script>
import FormValidation from "../../utils/FormValidation";
import { mapActions } from "vuex";
export default {
  name: "PartnersPage",
  data() {
    return {
      total_partners: 0,
      partnered_users: 0,
      subscribed_users: 0,
      payment_received: 0,
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Name", value: "name" },
        { text: "Unique ID", value: "ref_code" },
        { text: "Link", value: "ref_link" },
        { text: "Minutes", value: "minutes" },
        { text: "Number of Users", value: "partner_users_count" },
        { text: "Date Added", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      partners: [],
      search: "",
      dialog: false,
      form: {},
      item: {},
      loading: false,
      loadBtn: false,
      validate: new FormValidation(),
      snackbar: false,
      error_text: "",
    };
  },
  created() {
    this.getPartners();
  },
  methods: {
    ...mapActions("partners", [
      "get_partners",
      "add_partner",
      "update_partner",
      "delete_partner",
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.item = {};
      this.form = {};
      this.dialog = false;
    },
    async getPartners() {
      this.loading = true;
      try {
        const response = await this.get_partners();
        let res = response.data;
        this.partners = res.partners;
        this.total_partners = res.total_partners;
        this.partnered_users = res.partnered_users;
        this.subscribed_users = res.subscribed_users;
        this.payment_received = res.payment_received;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.snackbar = true;
        this.error_text = "Something went wrong, please contact support";
      }
    },
    editPartner(partner) {
      this.item = Object.assign({}, partner);
      this.form = Object.assign({}, this.item);
      this.openDialog();
    },
    async deletePartner(partner) {
      let confirmDelete = confirm("Action is not reversible...");
      if (confirmDelete) {
        this.loading = true;
        try {
          const response = await this.delete_partner(partner);
          this.getPartners();
          this.loading = false;
          this.$response.sendSuccess(response);
        } catch (e) {
          this.loading = false;
          this.$response.sendError(e);
        }
      }
    },
    async addPartner() {
      this.loadBtn = true;
      try {
        const response = await this.add_partner(this.form);
        this.getPartners();
        this.loadBtn = false;
        this.closeDialog();
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadBtn = false;
        this.$response.sendError(e);
      }
    },
    async updatePartner() {
      this.loadBtn = true;
      try {
        const response = await this.update_partner(this.form);
        this.getPartners();
        this.closeDialog();
        this.loadBtn = false;
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadBtn = false;
        this.$response.sendError(e);
      }
    },
  },
};
</script>

<style scoped></style>
